<template>
    <div class="pictrue_main_box wh100 pr">
        <HnContent :hasData='hasData'>
           <template slot="left">
                <HnMenu :menuDevType='menuDevType' @change='devChange' :actuatorFun="4"></HnMenu>
           </template>
           <template slot="right">
                <div class="content_box wh100 video-box-bg-image">
                    <div class="tools_box">
                        <HnDateSearch  
                        :isPage='true'
                        @update='dateUpdate'/>
                    </div>
                
                    <div class="sub_content_box" 
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(40, 53, 63, 0.8)"
                    v-loading="picListLoading">
                        <ul class="pic_list_box">
                            <template v-if="picList.length">
                                    <li v-for="(item,i) of picList" :key="i" class="infinite-list-item pr cp">
                                        <el-image
                                        style="width: 100%;height: 100%"
                                        :src="item.filePath"
                                        :preview-src-list="[item.filePath]"
                                        fit="cover">
                                            <div slot="error" class="image-slot boxBg">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                        <p>{{ item.photoTime || '无日期' }}</p>
                                    </li>
                                    <template v-for="(ele,index) of supplyBox" >
                                        <li class="supplyBox" :key="index+'qwe'"></li>
                                    </template>
                            </template>
                            <template v-else>
                                    <li class="not_data_box wh100">
                                        <img :src="noDataUrl" alt="">
                                        <p>暂无图片</p>
                                    </li>
                            </template>
                        </ul>
                        <div class="pages_box" v-if="isPageShow">
                            <el-pagination
                                @current-change="handleCurrentChange"
                                :current-page.sync="picFromInfo.pageIndex"
                                :page-size="15"
                                layout="total, prev, pager, next"
                                class="pageInfo"
                                background
                                :total="picTotal">
                            </el-pagination>
                        </div>
                    </div>
                </div>
           </template>
        </HnContent>
    </div>
</template>

<script>
    import { completionRow } from '@/libs/util'
    import menuFun from '@/mixins/menuFun'
    export default {
        mixins: [menuFun],
        data() {
            return {
                noDataUrl: require('@/assets/imgs/noData/no_data2.png'),
                menus:[],
                devActiveId:'',
                picFromInfo:{
                  pageSize: 15,
                  pageIndex: 1,
                  timeType: 0, 
                  sTime:'',
                  eTime: ''
               },
               picTotal: 0,
               picList:[],
               picListLoading: false,
               menuDevType: ['视频设备','采集设备'],
            }
        },
        computed:{
           supplyBox(){
                let supplyArr = completionRow(this.picList,5) 
                return supplyArr || []
           },
           isPageShow(){
              return this.picTotal > 14 ? true :false
           }
        },
        methods:{

          // 设备切换
          devChange(infos) {
              let data = infos[0]
              if(data){
                let { devId } = data
                this.devActiveId = devId
                this.reqIotDevicePhotoPageList()
              }
              
          },
          dateUpdate(data){
             Object.assign(this.picFromInfo,data)
             this.reqIotDevicePhotoPageList()
          },
          // 查看视频设备图片
          async reqIotDevicePhotoPageList(){
                const data = Object.assign({},this.picFromInfo,{devId: this.devActiveId}) 
                this.picListLoading= true
                const res = await this.$api.IotDevicePhotoPageList(data)
                setTimeout(()=> {
                    this.picListLoading= false
                },500)
                if(res){
                this.picTotal= res.totalCount 
                this.picList = res.list
                } else this.picList = []
             
          },
          // 分页
          handleCurrentChange(page){
             this.reqIotDevicePhotoPageList()
          }   
        }
    }
</script>

<style lang="scss" scoped>
.pictrue_main_box{
 
   .content_box{
       border-radius: 8px;
       box-sizing: border-box;
       padding: 40px 40px 20px 40px;
       .sub_content_box{
           height: calc(100% - 36px);
           width: 100%;
           display: flex;
           flex-direction: column;
           background-image: url('../../../assets/imgs/control/defalut/decoration_bg.png');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;
           .pic_list_box{
               flex: 1;
               display: flex;
               justify-content: space-between;
               flex-wrap: wrap;
               align-content: flex-start;
               overflow: hidden;
               .not_data_box{
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  & > p {
                     margin-top: 15px;
                  }
               }
               & > li.infinite-list-item{
                   width: 290px;
                   height: 200px;
                   margin-top: 18px;
                   .el-image ::v-deep .image-slot{
                       height: 100%;
                       display: flex;
                       justify-content: center;
                       align-items: center;
                       font-size: 24px;
                   }
               }
               .infinite-list-item{
                   box-sizing: border-box;
                   border: 1px solid transparent;
                   & > p {
                       font-size: 12px;
                       text-align: center;
                       padding: 5px 0;
                       width: 100%;
                       background: rgba(0, 0, 0, 0.7);
                       position: absolute;
                       left: 0;
                       bottom: 0;
                   }
               }
               .supplyBox{
                   width: 290px;
                   height: 200px;
                   background-color: transparent !important;
                }
           }
           .pages_box{
               margin: 10px 0;
               text-align: center;
           }
       }
       
    }
}
</style>