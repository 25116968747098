export default { 
    data(){
        return {
            hasData: true
        }
    },
    methods:{
        handleMenu(res){
             
            if(res && res.length) this.menus = res
            else this.hasData= false
        },
        getDevInfo(infos){
            let [info,menus] = infos
            let result = ''
            this.handleMenu(menus)
            menus.some(item => {
                if(item.devs && item.devs.length) {
                   return item.devs.some(ele => {
                       if(info.devId == ele.devId && info.id == ele.id){
                           result = ele
                           return true
                       }   
                   })
                }
            })
            return result

        }
    }
}